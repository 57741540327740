import { useState, useEffect } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Typography,
} from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import HomeIcon from "@mui/icons-material/Home";
import QrCodeIcon from "@mui/icons-material/QrCode";
import RoomIcon from "@mui/icons-material/Room";
import StoreIcon from "@mui/icons-material/Store";
import { useLocation, useNavigate } from "react-router-dom";

import main from "../assets/scss/main.module.scss";

export const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [menuSelect, setMenuSelect] = useState<string>(location.pathname);

  // パスに対応させてフッターナビの選択も変える
  // リロードやフッターナビ外からの遷移のための対応
  useEffect(() => {
    // スキャン時はフッターナビが描画されないため、ホームを選択させておく
    if (location.pathname === "/scan") {
      setMenuSelect("/");
    } else if (location.pathname.includes("/map")) {
      setMenuSelect("/map");
    } else {
      setMenuSelect(location.pathname);
    }
  }, [location.pathname]);

  return (
    <>
      {location.pathname !== "/scan" &&
        location.pathname !== "/customer-edit" && ( // スキャンとユーザ情報編集時はフッターナビ描画無し
          <>
            <Paper sx={{ m: 4, py: 4 }} elevation={0}>
              <Typography></Typography>
            </Paper>
            <Paper
              sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: main.colorLightGreen,
                pb: 2,
              }}
              elevation={3}
            >
              <BottomNavigation
                showLabels
                value={menuSelect}
                color="secondary"
                onChange={(event, newSelect: string) => {
                  setMenuSelect(newSelect);
                  navigate(newSelect);
                }}
                sx={{ backgroundColor: main.colorLightGreen }}
              >
                <BottomNavigationAction
                  label="ホーム"
                  value="/"
                  icon={<HomeIcon fontSize="medium" />}
                  sx={{ p: 0, minWidth: 0 }}
                />
                <BottomNavigationAction
                  label="マップ"
                  value="/map"
                  icon={<RoomIcon fontSize="medium" />}
                  sx={{ p: 0, minWidth: 0 }}
                />
                <BottomNavigationAction
                  label="支払う"
                  value="/scan"
                  icon={<QrCodeIcon fontSize="large" />}
                  sx={{
                    p: 0,
                    backgroundColor: main.colorDarkGreen,
                    color: "#fff",
                    borderRadius: "50%",
                    top: "-30px",
                    width: "75px",
                    minWidth: "75px",
                    maxWidth: "75px",
                    height: "75px",
                    minHeight: "75px",
                    maxHeight: "75px",
                  }}
                />
                <BottomNavigationAction
                  label="お得情報"
                  value="/deal"
                  icon={<StoreIcon fontSize="medium" />}
                  sx={{ p: 0, minWidth: 0 }}
                />
                <BottomNavigationAction
                  label="交換"
                  value="/change"
                  icon={<ChangeCircleIcon fontSize="medium" />}
                  sx={{ p: 0, minWidth: 0 }}
                />
              </BottomNavigation>
            </Paper>
          </>
        )}
    </>
  );
};
