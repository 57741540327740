import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import LoyaltyIcon from "@mui/icons-material/Loyalty";

import CustomAxios from "../utils/CustomAxios";
import main from "../assets/scss/main.module.scss";
import { RewardData, UsedRewardData } from "../type";

const REGION = process.env.REACT_APP_REGION as string;

type Props = {
  pointData: number;
  getCustomerInfo: () => void;
};

export const Change = ({ pointData, getCustomerInfo }: Props) => {
  const [rewardDataList, setRewardDataList] = useState<RewardData[]>([]);
  const [usedRewardDataList, setUsedRewardDataList] = useState<
    UsedRewardData[]
  >([]);
  const [selectReward, setSelectReward] = useState<RewardData>();
  const [isRewardLoading, setIsRewardLoading] = useState<boolean>(false);
  const [isUsedRewardLoading, setIsUsedRewardLoading] =
    useState<boolean>(false);
  const [isPointSpendModalOpen, setIsPointSpendModalOpen] =
    useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isSendLoading, setIsSendLoading] = useState<boolean>(false);
  const [isUseConfirm, setIsUseConfirm] = useState<boolean>(false);

  useEffect(() => {
    setIsRewardLoading(true);
    CustomAxios.get("/rewards", { params: { region: REGION } })
      .then((res) => {
        setRewardDataList(res.data.rewards);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsRewardLoading(false);
      });

    getUsedRewardData();
  }, []);

  const getUsedRewardData = () => {
    setIsUsedRewardLoading(true);
    CustomAxios.get("/customer/rewards")
      .then((res) => {
        setUsedRewardDataList(res.data.rewards);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsUsedRewardLoading(false);
      });
  };

  const handleReward = (item: RewardData) => {
    setIsPointSpendModalOpen(true);
    setSelectReward(item);
  };

  const onSend = () => {
    setIsSendLoading(true);
    CustomAxios.post(`/customer/reward/${selectReward?.uuid}`, {
      isUsed: true,
    })
      .then(() => {
        getCustomerInfo();
        setIsConfirmModalOpen(true);
        getUsedRewardData();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPointSpendModalOpen(false);
        setIsUseConfirm(false);
        setIsSendLoading(false);
      });
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{ backgroundColor: main.colorLightGreen, py: 2 }}
      >
        <Paper
          elevation={1}
          sx={{ width: "90%", mx: "auto", p: 2, borderRadius: "16px" }}
        >
          <Typography>保有ポイント</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
              mt: 1,
            }}
          >
            <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
              {pointData.toLocaleString()}
            </Typography>
            <Typography sx={{ ml: 1 }}>pt</Typography>
          </Box>
        </Paper>
      </Paper>
      <Typography sx={{ my: 2 }}>
        下記から交換したい景品を選んでください
      </Typography>
      {isRewardLoading && <CircularProgress />}
      {!isRewardLoading &&
        rewardDataList.map((item, index) => (
          <Paper
            key={index}
            onClick={() => handleReward(item)}
            sx={{ m: 2, px: 1, py: 2, textAlign: "left", cursor: "pointer" }}
          >
            <Paper
              elevation={0}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                m: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <LoyaltyIcon
                  fontSize="large"
                  sx={{ mr: 2, color: main.colorDarkGreen }}
                />
                <Box>
                  <Typography sx={{ fontSize: "20px" }}>{item.name}</Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {item.issuerName}
                  </Typography>
                </Box>
              </Box>
              <Paper
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ display: "inline", fontWeight: "bold", mx: 1 }}
                >
                  {item.point}
                </Typography>
                pt
              </Paper>
            </Paper>
            <Paper elevation={0} sx={{ textAlign: "right" }}>
              {pointData < item.point ? (
                <Chip
                  label={"ポイントが足りません"}
                  color="error"
                  size="small"
                  sx={{ color: "#FFF", ml: 4, px: 2 }}
                />
              ) : (
                ""
              )}
            </Paper>
          </Paper>
        ))}
      <Typography sx={{ my: 2 }}>交換済み景品</Typography>
      {isUsedRewardLoading && <CircularProgress />}
      {!isUsedRewardLoading &&
        usedRewardDataList.map((item, index) => (
          <Paper
            key={index}
            sx={{
              m: 2,
              px: 1,
              py: 2,
              textAlign: "left",
              backgroundColor: item.availableFlag ? "white" : "lightGray",
            }}
          >
            <Paper
              elevation={0}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                m: 2,
                backgroundColor: "transparent",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <LoyaltyIcon
                  fontSize="large"
                  sx={{ mr: 2, color: main.colorDarkGreen }}
                />
                <Box>
                  <Typography sx={{ fontSize: "20px" }}>{item.name}</Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {item.issuerName}
                  </Typography>
                </Box>
              </Box>
              <Paper
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "transparent",
                }}
              >
                <Typography
                  sx={{
                    display: "inline",
                    fontSize: "24px",
                    fontWeight: "bold",
                    mx: 1,
                  }}
                >
                  {item.point}
                </Typography>
                pt
              </Paper>
            </Paper>
            {item.availableFlag ? (
              ""
            ) : (
              <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
                <Chip
                  label={"使用済み"}
                  color="info"
                  size="small"
                  sx={{ color: "#FFF", ml: 1, px: 2 }}
                />
                <Typography sx={{ ml: 1, display: "inline" }}>
                  {item.usedAt}
                </Typography>
              </Paper>
            )}
          </Paper>
        ))}
      <Modal open={isPointSpendModalOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            width: "80%",
          }}
        >
          <Paper elevation={0} sx={{ mx: 1 }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color="primary"
              sx={{
                textAlign: "left",
                my: 1,
              }}
            >
              以下の景品を交換しますか？
            </Typography>
            <Typography variant="h5">
              {selectReward?.name} {selectReward?.point}pt
            </Typography>
            {pointData >= Number(selectReward?.point) && (
              <>
                <Typography sx={{ my: 2 }}>
                  景品を使用する際は、この画面を係りの者にお見せください。
                </Typography>
                <FormControlLabel
                  label="確認しました"
                  control={
                    <Checkbox
                      onChange={(e) => setIsUseConfirm(e.target.checked)}
                    />
                  }
                />
              </>
            )}
            {pointData < Number(selectReward?.point) && (
              <Typography sx={{ my: 2 }}>ポイントが不足しています。</Typography>
            )}
          </Paper>

          {isSendLoading && (
            <Box sx={{ textAlign: "center", m: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {!isSendLoading && (
            <Paper elevation={0} sx={{ m: 2 }}>
              <Button
                color="primary"
                variant="outlined"
                size="large"
                fullWidth
                onClick={() => {
                  setIsPointSpendModalOpen(false);
                }}
                sx={{ mb: 2 }}
              >
                戻る
              </Button>
              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                onClick={onSend}
                disabled={!isUseConfirm}
              >
                使う
              </Button>
            </Paper>
          )}
        </Box>
      </Modal>
      <Modal open={isConfirmModalOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            width: "80%",
          }}
        >
          <Paper elevation={0} sx={{ mx: 2 }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color="primary"
              sx={{
                textAlign: "left",
                my: 1,
              }}
            >
              「{selectReward?.name}」を使用しました。
            </Typography>
          </Paper>
          <Paper elevation={0} sx={{ m: 2 }}>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              fullWidth
              onClick={() => setIsConfirmModalOpen(false)}
            >
              閉じる
            </Button>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};
