import { useState, useEffect } from "react";
import { Chip, CircularProgress, Paper, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import CustomAxios from "../utils/CustomAxios";
import main from "../assets/scss/main.module.scss";
import { HistoryData } from "../type";

export const History = () => {
  const [historyData, setHistoryData] = useState<HistoryData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    CustomAxios.get("/customer/payments")
      .then((res) => {
        setHistoryData(res.data.payments);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Typography variant="h5" sx={{ my: 1 }}>
        支払履歴
      </Typography>
      {isLoading && <CircularProgress />}
      {!isLoading &&
        historyData.map((item, index) => (
          <Paper
            key={index}
            sx={{
              m: 2,
              px: 2,
              py: 2,
              textAlign: "left",
              display: "flex",
            }}
          >
            {item.value < 0 &&
              item.issuerName !== "返金" &&
              item.issuerName !== "失効" && (
                <ShoppingCartIcon
                  fontSize="large"
                  sx={{
                    margin: "auto 10px auto 0px",
                    color: main.colorDarkGreen,
                  }}
                />
              )}
            {item.issuerName === "返金" && (
              <KeyboardReturnIcon
                fontSize="large"
                sx={{
                  margin: "auto 10px auto 0px",
                  color: main.colorDarkGreen,
                }}
              />
            )}
            {item.issuerName === "失効" && (
              <HighlightOffIcon
                fontSize="large"
                sx={{
                  margin: "auto 10px auto 0px",
                  color: "red",
                }}
              />
            )}
            {item.value > 0 && (
              <AddCircleOutlineIcon
                fontSize="large"
                sx={{
                  margin: "auto 10px auto 0px",
                  color: main.colorDarkGreen,
                }}
              />
            )}
            <Paper elevation={0} sx={{ width: "100%" }}>
              <Typography>{item.date}</Typography>
              <Typography sx={{ my: 1, fontSize: "20px" }}>
                {item.issuerName}
              </Typography>
              <Paper
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      display: "inline",
                      fontSize: "24px",
                      fontWeight: "bold",
                      mr: 1,
                    }}
                  >
                    {item.value.toLocaleString()}
                  </Typography>
                  円
                </Paper>
                {item.expireDate && (
                  <Chip
                    label={`有効期限 ${item.expireDate}`}
                    color="error"
                    sx={{ color: "#FFF", ml: "auto", px: 1 }}
                  />
                )}
                {item.point > 0 && (
                  <Chip
                    label={`+${item.point}pt`}
                    color="info"
                    sx={{ color: "#FFF", ml: "auto", px: 2 }}
                  />
                )}
              </Paper>
            </Paper>
          </Paper>
        ))}
    </>
  );
};
