import { useState, useEffect } from "react";
import jsQR from "jsqr";
import Webcam from "react-webcam";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";

import { useNoticeContext } from "../../context/NoticeContext";
import CustomAxios from "../../utils/CustomAxios";
import main from "../../assets/scss/main.module.scss";
import iPhone1 from "../../assets/image/iphone1.jpg";
import iPhone2 from "../../assets/image/iphone2.jpg";
import iPhone3 from "../../assets/image/iphone3.jpg";
import iPhone4 from "../../assets/image/iphone4.jpg";
import iPhone5 from "../../assets/image/iphone5.jpg";
import iPhone6 from "../../assets/image/iphone6.jpg";
import android1 from "../../assets/image/android1.jpg";
import android2 from "../../assets/image/android2.jpg";
import android3 from "../../assets/image/android3.jpg";
import android4 from "../../assets/image/android4.jpg";
import android5 from "../../assets/image/android5.jpg";
import android6 from "../../assets/image/android6.jpg";

const VIDEO_CONST = {
  width: 375,
  height: 375,
  facingMode: "environment",
};

type Props = {
  issuerId: string;
  setIssuerId: (arg: string) => void;
  setIssuerName: (arg: string) => void;
  setIssuerType: (arg: string) => void;
  setIsScanOpen: (arg: boolean) => void;
  setIsInputAreaOpen: (arg: boolean) => void;
  setSelectRate: (arg: number) => void;
};

export const QrRead = ({
  issuerId,
  setIssuerId,
  setIssuerName,
  setIssuerType,
  setIsScanOpen,
  setIsInputAreaOpen,
  setSelectRate,
}: Props) => {
  const navigate = useNavigate();
  const { setNotice, setSeverity } = useNoticeContext();

  const [isScanLoading, setIsScanLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tabSelect, setTabSelect] = useState(
    window.navigator.userAgent.toLowerCase().includes("android") ? 1 : 0
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelect(newValue);
  };

  const refreshScan = () => {
    setIsScanLoading(false);
    navigate("/");
    setIssuerId("");
  };

  const handleCapture = () => {
    const video = document.getElementById("video") as HTMLImageElement;
    const cvs = document.getElementById("camera-canvas") as HTMLCanvasElement;
    if (!cvs) {
      return;
    }

    const ctx = cvs.getContext("2d");
    cvs.width = video.clientWidth;
    cvs.height = video.clientHeight;
    ctx?.drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
    const imageData = ctx?.getImageData(
      0,
      0,
      video.clientWidth,
      video.clientHeight
    );
    if (!imageData) {
      return;
    }

    const code = jsQR(imageData.data, video.clientWidth, video.clientHeight);

    if (code) {
      setIsScanLoading(true);
      const codeDataList = code.data.split(".");
      const codeType = codeDataList.length === 2 ? codeDataList[1] : null;

      if (codeType === "stamp") {
        CustomAxios.post(`/customer/rally/${codeDataList[0]}`)
          .then(() => {
            navigate(`/stamp-rally/${codeDataList[0]}`);
          })
          .catch((err) => {
            console.log(err);
            setNotice("QRコードが不正です");
            setSeverity("error");
            refreshScan();
          });
      } else {
        setIssuerId(codeDataList[0]);
        CustomAxios.get(`/issuer/${codeDataList[0]}`, {
          params: { noPic: true },
        })
          .then((res) => {
            setIssuerName(res.data.name);
            setSelectRate(res.data.customerRate);
            setIssuerType(res.data.type);
            if (res.data.qrcodeType === "pay") {
              setIsInputAreaOpen(true);
              setIsScanLoading(false);
              setIsScanOpen(false);
            } else {
              refreshScan();
            }
          })
          .catch((err) => {
            console.log(err);
            setNotice("QRコードが不正です");
            setSeverity("error");
            refreshScan();
          });
      }
    }
  };

  useEffect(() => {
    if (!issuerId) {
      const interval = setInterval(() => {
        handleCapture();
      }, 500);
      return () => {
        clearInterval(interval);
      };
    }
  });

  return (
    <>
      <Webcam
        id="video"
        className="scan_video"
        audio={false}
        width={VIDEO_CONST.width}
        height={VIDEO_CONST.height}
        screenshotFormat="image/jpeg"
        videoConstraints={VIDEO_CONST}
      />
      {isScanLoading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isScanLoading && (
        <>
          <div className="scan_canvas">
            <canvas id="camera-canvas" className="scan_canvas" />
          </div>
          <Box
            width="100%"
            height="calc((100% - 300px) / 2)"
            sx={{
              position: "absolute",
              top: 0,
              left: "50%",
              transform: "translate(-50%, 0%)",
              background: "rgba(0,0,0,0.5);",
            }}
          ></Box>
          <Box
            width="calc((100% - 300px) / 2)"
            height="300px"
            sx={{
              position: "absolute",
              bottom: "50%",
              right: 0,
              transform: "translate(0%, 50%)",
              background: "rgba(0,0,0,0.5);",
            }}
          ></Box>
          <Box
            width="calc((100% - 300px) / 2)"
            height="300px"
            sx={{
              position: "absolute",
              bottom: "50%",
              left: 0,
              transform: "translate(0%, 50%)",
              background: "rgba(0,0,0,0.5);",
            }}
          ></Box>
          <Box
            width="100%"
            height="calc((100% - 300px) / 2)"
            sx={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translate(-50%, 0%)",
              background: "rgba(0,0,0,0.5);",
            }}
          ></Box>
          <Box
            width="300px"
            height="300px"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              border: "10px dashed",
              borderColor: main.colorGreen,
              backgroundColor: "transparent",
              boxSizing: "initial",
            }}
          ></Box>
        </>
      )}
      <Paper
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: "transparent",
          pt: 4,
        }}
        elevation={0}
      >
        <Button
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => setIsModalOpen(true)}
          startIcon={<InfoIcon />}
          sx={{ m: 2, backgroundColor: "white" }}
        >
          カメラが起動しない時は
        </Button>
      </Paper>
      <Paper
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "transparent",
          pb: 2,
        }}
        elevation={0}
      >
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={refreshScan}
          startIcon={<CloseIcon />}
          sx={{ m: 2 }}
        >
          閉じる
        </Button>
      </Paper>
      <Modal open={isModalOpen}>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            m: 2,
            p: 2,
            bgcolor: "background.paper",
            borderRadius: "16px",
            overflowY: "scroll",
            height: "95%",
          }}
        >
          <Tabs
            variant="fullWidth"
            centered
            value={tabSelect}
            onChange={handleTabChange}
            aria-label="利用方法動画選択"
            sx={{ mb: "5px" }}
          >
            <Tab
              label="iPhone"
              sx={{ m: "auto", fontSize: "20px", textTransform: "none" }}
            />
            <Tab
              label="Android"
              sx={{ m: "auto", fontSize: "20px", textTransform: "none" }}
            />
          </Tabs>
          {tabSelect === 0 && (
            <Paper
              elevation={0}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Typography variant="h6" sx={{ mt: 2 }}>
                手順1
              </Typography>
              ①設定→Safari
              <img
                style={{ width: "100%", maxWidth: "500px" }}
                src={iPhone1}
                alt="iPhone1"
              />
              ②カメラ→拒否を許可に
              <img
                style={{ width: "100%", maxWidth: "500px" }}
                src={iPhone2}
                alt="iPhone2"
              />
              <img
                style={{ width: "100%", maxWidth: "500px" }}
                src={iPhone3}
                alt="iPhone3"
              />
              <img
                style={{ width: "100%", maxWidth: "500px" }}
                src={iPhone4}
                alt="iPhone4"
              />
              <Typography variant="h6" sx={{ mt: 2 }}>
                手順2
              </Typography>
              ①設定→Safari
              <br />
              <br />
              ②履歴とWebサイトデー消去
              <img
                style={{ width: "100%", maxWidth: "500px" }}
                src={iPhone5}
                alt="iPhone5"
              />
              ③全ての履歴、タブを全て閉じる、履歴を消去
              <img
                style={{ width: "100%", maxWidth: "500px" }}
                src={iPhone6}
                alt="iPhone6"
              />
            </Paper>
          )}
          {tabSelect === 1 && (
            <Paper
              elevation={0}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Typography variant="h6" sx={{ mt: 2 }}>
                手順1
              </Typography>
              ①設定→アプリ→Chrome→権限
              <img
                style={{ width: "100%", maxWidth: "500px" }}
                src={android1}
                alt="android1"
              />
              <img
                style={{ width: "100%", maxWidth: "500px" }}
                src={android2}
                alt="android2"
              />
              <img
                style={{ width: "100%", maxWidth: "500px" }}
                src={android3}
                alt="android3"
              />
              ②カメラの権限アプリを「アプリの使用中のみ許可」にチェック
              <img
                style={{ width: "100%", maxWidth: "500px" }}
                src={android4}
                alt="android4"
              />
              <Typography variant="h6" sx={{ mt: 2 }}>
                手順2
              </Typography>
              ①設定→アプリ→Chrome
              <br />
              <br />
              ②キャッシュを削除
              <img
                style={{ width: "100%", maxWidth: "500px" }}
                src={android5}
                alt="android5"
              />
              <img
                style={{ width: "100%", maxWidth: "500px" }}
                src={android6}
                alt="android6"
              />
            </Paper>
          )}
          <Paper sx={{ ml: 2, mr: 2, mb: 2, mt: 10 }}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              onClick={() => setIsModalOpen(false)}
            >
              閉じる
            </Button>
          </Paper>
        </Box>
      </Modal>
    </>
  );
};
