import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import CustomAxios from "./utils/CustomAxios";
import { Change } from "./pages/Change";
import { CustomerEdit } from "./pages/CustomerEdit";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { History } from "./pages/History";
import { Home } from "./pages/Home";
import { ManualCharge } from "./pages/ManualCharge";
import { Map } from "./pages/Map";
import { Scan } from "./pages/Scan";
import { Deal } from "./pages/Deal";
import { MapDetail } from "./pages/MapDetail";
import { Terms } from "./pages/Terms";
import { StampRally } from "./pages/StampRally";
import { CustomerInfo } from "./type";
import { NoticeProvider } from "./context/NoticeContext";

const REGION = process.env.REACT_APP_REGION as string;

export const App = () => {
  const [customerId, setCustomerId] = useState<string>("");
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>();
  const [pointData, setPointData] = useState<number>(0);
  const [balanceData, setBalanceData] = useState<number>(0);
  const [reduceCO2Data, setReduceCO2Data] = useState<number>(0);
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);

  const getCustomerInfo = async () => {
    setIsCustomerLoading(true);
    try {
      const res = await CustomAxios.get("/customer");
      setCustomerId(res.data.uuid);
      setPointData(Number(res.data.balanceList[REGION].point));
      setBalanceData(Number(res.data.balanceList[REGION].value));
      setReduceCO2Data(res.data.reduceCo2);
      setCustomerInfo({
        uuid: res.data.uuid,
        email: res.data.email,
        phoneNumber: res.data.phoneNumber,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsCustomerLoading(false);
    }
  };

  useEffect(() => {
    getCustomerInfo();
  }, []);

  return (
    <NoticeProvider>
      <BrowserRouter>
        <div className="app">
          <Header customerInfo={customerInfo} />
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  getCustomerInfo={getCustomerInfo}
                  isCustomerLoading={isCustomerLoading}
                  reduceCO2Data={reduceCO2Data}
                  balanceData={balanceData}
                />
              }
            />
            <Route
              path="/scan"
              element={
                <Scan
                  getCustomerInfo={getCustomerInfo}
                  balanceData={balanceData}
                />
              }
            />
            <Route path="/map">
              <Route index={true} element={<Map />} />
              <Route path="/map/:issuerId" element={<MapDetail />} />
            </Route>
            <Route path="/deal" element={<Deal />} />
            <Route
              path="/change"
              element={
                <Change
                  pointData={pointData}
                  getCustomerInfo={getCustomerInfo}
                />
              }
            />
            <Route path="/history" element={<History />} />
            <Route
              path="/manual-charge"
              element={
                <ManualCharge
                  customerId={customerId}
                  getCustomerInfo={getCustomerInfo}
                />
              }
            />
            <Route
              path="/customer-edit"
              element={
                <CustomerEdit
                  customerInfo={customerInfo as CustomerInfo}
                  getCustomerInfo={getCustomerInfo}
                />
              }
            />
            <Route path="/terms" element={<Terms />} />
            <Route path="/stamp-rally/:stampRallyId" element={<StampRally />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </NoticeProvider>
  );
};
