import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import StoreIcon from "@mui/icons-material/Store";

import { Balance } from "../../components/Balance";
import main from "../../assets/scss/main.module.scss";

type Props = {
  balanceData: number;
  issuerName: string;
  setIsInputAreaOpen: (args: boolean) => void;
  inputPay: string;
  setInputPay: (args: string) => void;
  isInputError: boolean;
  setIsInputError: (args: boolean) => void;
  setIsConfirmOpen: (args: boolean) => void;
};

export const InputPayment = ({
  balanceData,
  issuerName,
  setIsInputAreaOpen,
  inputPay,
  setInputPay,
  isInputError,
  setIsInputError,
  setIsConfirmOpen,
}: Props) => {
  const navigate = useNavigate();

  const [isFocus, setIsFocus] = useState(false);

  const handleInputPay = (v: string) => {
    if (v.match(/^[0-9]*$/)) {
      setInputPay(v);
      setIsInputError(Number(v) > balanceData);
    }
  };

  return (
    <>
      <div className="issuerName">
        <StoreIcon
          fontSize="large"
          sx={{
            p: "5px",
            backgroundColor: main.colorGreen,
            color: "#fff",
            borderRadius: "50%",
            width: "50px",
            minWidth: "50px",
            maxWidth: "50px",
            height: "50px",
            minHeight: "50px",
            maxHeight: "50px",
          }}
        />
        <span>{issuerName}</span>
      </div>
      <Paper
        elevation={0}
        sx={{ backgroundColor: main.colorLightGreen, py: 2 }}
      >
        <Paper
          elevation={1}
          sx={{
            width: "90%",
            mx: "auto",
            p: 2,
            borderRadius: "16px",
          }}
        >
          <Balance balanceData={Number(balanceData)} />
        </Paper>
      </Paper>
      <FormControl sx={{ mx: "30px", mt: "20px" }}>
        <InputLabel
          htmlFor="component-simple"
          sx={{ backgroundColor: "#fff", fontSize: "20px", px: "5px" }}
        >
          支払い金額を入力
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          startAdornment={
            <InputAdornment
              position="start"
              sx={{ fontSize: 34, fontWeight: "bold" }}
            >
              <CurrencyYenIcon />
            </InputAdornment>
          }
          autoFocus={true}
          type="number"
          required={true}
          value={inputPay}
          onChange={(e) => handleInputPay(e.target.value)}
          sx={{ fontSize: 34, fontWeight: "bold" }}
          inputProps={{ min: "0" }}
          error={isInputError}
          onFocus={() => {
            setIsFocus(true);
            window.scroll(0, 120);
          }}
          onBlur={() => {
            setIsFocus(false);
            window.scroll(0, 0);
          }}
        />
        {isInputError && (
          <FormHelperText error sx={{ fontSize: "14px" }}>
            金額が不足しています
          </FormHelperText>
        )}
      </FormControl>
      <Paper
        sx={{
          position: isFocus ? "inherit" : "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          m: 2,
          pb: 2,
        }}
        elevation={0}
      >
        <Button
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          onClick={() => {
            setIsConfirmOpen(true);
            setIsInputAreaOpen(false);
          }}
          disabled={isInputError || inputPay === "" || inputPay === "0"}
          startIcon={<CheckIcon />}
          sx={{ mb: 2 }}
        >
          確認
        </Button>
        <Button
          color="primary"
          variant="outlined"
          size="large"
          fullWidth
          onClick={() => navigate("/")}
          startIcon={<CloseIcon />}
        >
          閉じる
        </Button>
      </Paper>
    </>
  );
};
