import { useState } from "react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

import logoWhite from "../assets/image/logo_white.png";
import logoWhiteBig from "../assets/image/logo_white_big.png";
import main from "../assets/scss/main.module.scss";
import { CustomerInfo } from "../type";

type Props = {
  customerInfo: CustomerInfo | undefined;
};

export const Header = ({ customerInfo }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { signOut } = useAuthenticator((context) => [context.user]);
  const [leftMenuOpen, setLeftMenuOpen] = useState<boolean>(false);
  const [rightMenuOpen, setRightMenuOpen] = useState<boolean>(false);

  const handleLeftDrawerToggle = () => {
    setLeftMenuOpen((prevState) => !prevState);
  };

  const handleRightDrawerToggle = () => {
    setRightMenuOpen((prevState) => !prevState);
  };

  const leftDrawer = (
    <Box onClick={handleLeftDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        EcoPay
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => navigate("/")}
          >
            <ListItemText primary={"ホーム"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() => navigate("/terms")}
          >
            <ListItemText primary={"利用規約"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }} onClick={signOut}>
            <ListItemText
              primary={"ログアウト"}
              primaryTypographyProps={{ color: "red", fontSize: "14px" }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const rightDrawer = (
    <Box onClick={handleRightDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        ユーザ情報
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding sx={{ textAlign: "center", height: "60px" }}>
          <ListItemText
            primary="メールアドレス"
            secondary={customerInfo?.email}
          />
        </ListItem>
        <ListItem disablePadding sx={{ textAlign: "center", height: "60px" }}>
          <ListItemText
            primary="電話番号"
            secondary={
              customerInfo?.phoneNumber
                ? customerInfo.phoneNumber.replace("+81", "")
                : ""
            }
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center", mt: 2, fontSize: "12px" }}
            onClick={() => navigate("/customer-edit")}
          >
            <ListItemText
              primary={"編集する"}
              primaryTypographyProps={{ color: main.colorDarkGreen }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      {location.pathname !== "/scan" &&
        location.pathname !== "/customer-edit" &&
        !location.pathname.includes("/map/") && ( // スキャンとユーザ情報編集時はヘッダー描画無し
          <>
            <AppBar position="sticky" color="primary">
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleLeftDrawerToggle}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
                <div className="header__title">
                  <img src={logoWhite} height={60} alt="ロゴ" />
                  <img src={logoWhiteBig} height={60} alt="ロゴ" />
                </div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleRightDrawerToggle}
                  sx={{ pr: 0 }}
                >
                  <AccountCircleIcon fontSize="large" />
                </IconButton>
              </Toolbar>
            </AppBar>
            <nav>
              <Drawer
                variant="temporary"
                anchor={"left"}
                open={leftMenuOpen}
                onClose={handleLeftDrawerToggle}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  "& .MuiDrawer-paper": { boxSizing: "border-box", width: 150 },
                }}
              >
                {leftDrawer}
              </Drawer>
              <Drawer
                variant="temporary"
                anchor={"right"}
                open={rightMenuOpen}
                onClose={handleRightDrawerToggle}
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  "& .MuiDrawer-paper": { boxSizing: "border-box", width: 250 },
                }}
              >
                {rightDrawer}
              </Drawer>
            </nav>
          </>
        )}
    </>
  );
};
