import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Paper, Typography } from "@mui/material";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomAxios from "../utils/CustomAxios";

const stampRallyData = {
  name: "寺エコスタンプラリー",
  stampNum: 3,
};
const stampMax = 10;

export const StampRally = () => {
  const { stampRallyId } = useParams();

  const [stampRallyData, setStampRallyData] = useState<{ name: string }[]>([]);

  useEffect(() => {
    CustomAxios.get(`/customer/rally?rallyId=${stampRallyId}`).then((res) => {
      setStampRallyData(res.data.rally);
    });
  }, []);

  return (
    <>
      <Paper
        sx={{
          width: "90%",
          mx: "auto",
          mt: 4,
          p: 2,
          borderRadius: "16px",
        }}
      >
        <Typography variant="h5" sx={{ mt: 1, mb: 2 }}>
          {stampRallyData[0]?.name}
        </Typography>
        <Container
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5,50px)",
            justifyContent: "center",
            justifyItems: "center",
            gap: "10px 10px",
          }}
        >
          {[...Array(stampRallyData.length)].map(() => (
            <CheckCircleIcon fontSize="large" color="primary" />
          ))}
          {[...Array(stampMax - stampRallyData.length)].map(() => (
            <Brightness1Icon fontSize="large" sx={{ color: "gray" }} />
          ))}
        </Container>
      </Paper>
    </>
  );
};
