import { useState } from "react";

import { QrRead } from "../components/scan/QrRead";
import { InputPayment } from "../components/scan/InputPayment";
import { Confirm } from "../components/scan/Confirm";

type Props = {
  getCustomerInfo: () => void;
  balanceData: number;
};

export const Scan = ({ getCustomerInfo, balanceData }: Props) => {
  const [issuerId, setIssuerId] = useState<string>("");
  const [issuerName, setIssuerName] = useState<string>("");
  const [issuerType, setIssuerType] = useState<string>("");
  const [isScanOpen, setIsScanOpen] = useState<boolean>(true);
  const [isInputAreaOpen, setIsInputAreaOpen] = useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [inputPay, setInputPay] = useState<string>("");
  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [selectRate, setSelectRate] = useState<number>();

  return (
    <>
      {isScanOpen && (
        <QrRead
          issuerId={issuerId}
          setIssuerId={setIssuerId}
          setIssuerName={setIssuerName}
          setIssuerType={setIssuerType}
          setIsScanOpen={setIsScanOpen}
          setIsInputAreaOpen={setIsInputAreaOpen}
          setSelectRate={setSelectRate}
        />
      )}
      {isInputAreaOpen && (
        <InputPayment
          balanceData={balanceData}
          issuerName={issuerName}
          setIsInputAreaOpen={setIsInputAreaOpen}
          inputPay={inputPay}
          setInputPay={setInputPay}
          isInputError={isInputError}
          setIsInputError={setIsInputError}
          setIsConfirmOpen={setIsConfirmOpen}
        />
      )}
      {isConfirmOpen && (
        <Confirm
          issuerId={issuerId}
          issuerName={issuerName}
          setIssuerName={setIssuerName}
          issuerType={issuerType}
          setIsInputAreaOpen={setIsInputAreaOpen}
          setIsConfirmOpen={setIsConfirmOpen}
          inputPay={inputPay}
          getCustomerInfo={getCustomerInfo}
          selectRate={selectRate}
          setSelectRate={setSelectRate}
        />
      )}
    </>
  );
};
