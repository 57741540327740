import { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

import { useNoticeContext } from "../context/NoticeContext";
import CustomAxios from "../utils/CustomAxios";
import { CustomerInfo } from "../type";

type Props = {
  customerInfo: CustomerInfo;
  getCustomerInfo: () => void;
};

export const CustomerEdit = ({ customerInfo, getCustomerInfo }: Props) => {
  const navigate = useNavigate();
  const { notice, setNotice, severity, setSeverity } = useNoticeContext();

  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);

  useEffect(() => {
    setNotice(null);
  }, [setNotice]);

  useEffect(() => {
    if (customerInfo) {
      setEmail(customerInfo.email);
      setPhoneNumber(
        customerInfo.phoneNumber
          ? customerInfo.phoneNumber.replace("+81", "")
          : ""
      );
    }
  }, [customerInfo]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const sendData = {
      email: email,
      phoneNumber: phoneNumber ? `+81${phoneNumber}` : "",
    };
    setIsLoading(true);
    CustomAxios.put("/customer", sendData)
      .then(() => {
        getCustomerInfo();
        setNotice("ユーザ情報を更新しました");
        setSeverity("success");
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setNotice("エラーが発生しました");
        setSeverity("error");
      })
      .finally(() => setIsLoading(false));
  };

  const handleCustomerDelete = () => {
    setIsLoading(true);
    CustomAxios.delete("/customer")
      .then(() => {
        setNotice(
          "退会処理が完了しました。ログアウトしてください。ご利用ありがとうございました"
        );
        setSeverity("success");
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setNotice("エラーが発生しました");
        setSeverity("error");
      })
      .finally(() => {
        setIsLoading(false);
        setIsModalOpen(false);
      });
  };

  return (
    <>
      <Typography variant="h5" sx={{ my: 2, position: "relative" }}>
        <IconButton
          color="primary"
          size="large"
          onClick={() => navigate("/")}
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            my: "auto",
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        ユーザ情報編集
      </Typography>
      {!customerInfo && <CircularProgress sx={{ my: 4 }} />}
      {customerInfo && (
        <>
          <Paper elevation={0} sx={{ mt: 4 }}>
            <form onSubmit={handleSubmit}>
              <FormControl sx={{ width: "100%", px: 2 }}>
                <FormLabel>メールアドレス</FormLabel>
                <TextField
                  defaultValue={customerInfo.email}
                  type="email"
                  variant="outlined"
                  placeholder="メールアドレスを入力"
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <FormLabel>電話番号</FormLabel>
                <TextField
                  defaultValue={
                    customerInfo.phoneNumber
                      ? customerInfo.phoneNumber.replace("+81", "")
                      : ""
                  }
                  type="tel"
                  variant="outlined"
                  placeholder="電話番号を入力"
                  fullWidth
                  onChange={(e) =>
                    setPhoneNumber(e.target.value.replace("-", ""))
                  }
                />
                <Typography sx={{ mb: 2, color: "red" }}>
                  ※電話番号を設定することで二段階認証が有効化されます
                  <br />
                  ※SMSを受信できる番号を設定してください
                </Typography>
                {isLoading && <CircularProgress sx={{ mx: "auto" }} />}
                {!isLoading && (
                  <Button variant="contained" type="submit">
                    送信
                  </Button>
                )}
              </FormControl>
            </form>
          </Paper>
          {notice && (
            <Alert
              severity={severity}
              onClose={() => setNotice(null)}
              sx={{ width: "70%", mx: "auto", my: "30px" }}
            >
              {notice}
            </Alert>
          )}
          {!isLoading && (
            <Button
              variant="text"
              sx={{
                textAlign: "center",
                mt: 4,
                fontSize: "12px",
                color: "red",
              }}
              onClick={() => setIsModalOpen(true)}
            >
              退会する
            </Button>
          )}
          <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                mx: 2,
                mb: 8,
                px: 2,
                bgcolor: "background.paper",
                borderRadius: "16px",
                height: "400px",
              }}
            >
              <Paper elevation={0} sx={{ height: "200px" }}>
                <Typography
                  variant="h6"
                  component="h2"
                  color="primary"
                  sx={{
                    textAlign: "center",
                    p: 1,
                  }}
                >
                  本当に退会しますか？
                </Typography>
                <Typography sx={{ m: 2 }}>
                  一度退会するとアカウント復元及び返金はできません。
                </Typography>
                <FormControlLabel
                  label="確認しました"
                  control={
                    <Checkbox
                      onChange={(e) => setIsConfirm(e.target.checked)}
                    />
                  }
                />
              </Paper>
              <Paper
                elevation={0}
                sx={{ mx: 2, mt: "auto", textAlign: "center" }}
              >
                {isLoading && <CircularProgress sx={{ mx: "auto" }} />}
                {!isLoading && (
                  <>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="large"
                      fullWidth
                      onClick={handleCustomerDelete}
                      disabled={!isConfirm}
                      sx={{ my: 2 }}
                    >
                      退会する
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      fullWidth
                      onClick={() => setIsModalOpen(false)}
                    >
                      戻る
                    </Button>
                  </>
                )}
              </Paper>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};
